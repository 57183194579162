import React, { useState, useEffect, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import * as XLSX from 'xlsx';
import baseStandards from '../../baseStandards/baseStandards.json';
import { useWorkspaceStandard } from '../../contexts/InitiateContexts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SaveIcon from '@mui/icons-material/Save';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Col, Container, Row, Tab, Nav, Table, Form, OverlayTrigger, Tooltip, Modal, ToggleSwitch } from 'react-bootstrap';
import CodePart from './CodePart';
import CodeValueEntry from './CodeValueEntry';
import { HTML5Backend } from 'react-dnd-html5-backend';


const CustomiseWorkspaceStandard = ({ blueprints, template, firstTimeConfig, handleSetFirstTimeConfig, clearStandard, handleSetClearStandard, handleSetToastMessage, hasCodeParts, setHasCodeParts, uploadFromExcel, step, setAllCodePartsHaveValues }) => {
    const [baseStandard, setBaseStandard] = useState(null);
    const { workspaceStandard, dispatch } = useWorkspaceStandard();
    const [codeParts, setCodeParts] = useState([]);
    const [tableData, setTableData] = useState([]);

    const [showTableModal, setShowTableModal] = useState(false);
    const [activeCodePart, setActiveCodePart] = useState(null);
    const [isCollapsed, setCollapsed] = useState(true);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false); // State variable for showing/hiding the modal to clear standard
    const [showOverwriteConfirmation, setShowOverwriteConfirmation] = useState(false);
    const [openEditor, setOpenEditor] = useState(false);

    const [selectedTab, setSelectedTab] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedRows, setSelectedRows] = useState(new Set());
    const isSelected = selectedRows.size > 0;

    const [categoryName, setCategoryName] = useState('');

    const [showPrompt, setShowPrompt] = useState(false);

    console.log("BASE STANDARD", baseStandard)

    // Function to update the base standard on component mount. 
    useEffect(() => {
        // Check if this is the first time config and load either blank standard or template.
        if (firstTimeConfig && blueprints) {  // Ensure blueprints is defined

            // Find the blueprint that matches the template
            const selectedBlueprint = blueprints.find(blueprint => blueprint.name === template);

            // If a matching blueprint is found, set baseStandard to its data; otherwise, set it to an empty array
            setBaseStandard(selectedBlueprint ? selectedBlueprint : []);

            // Ensure the boolean is only reset when the user has clicked to this page.
            if (template != null) {
                handleSetFirstTimeConfig();
            }
        }

        // Check if the user made a change on the Define Standard step and if the standard needs to be updated.
        else if (clearStandard && blueprints) {  // Ensure baseStandards is defined

            deleteAllCodeParts();

            // Find the section that matches the template in baseStandards
            const selectedBlueprint = blueprints.find(blueprint => blueprint.name === template);

            // If a matching section is found, set baseStandard to its data; otherwise, clear it
            setBaseStandard(selectedBlueprint ? selectedBlueprint : clearBaseStandard());

            // Set clearStandard to false.
            handleSetClearStandard(false);
        }
    }, [template, blueprints, firstTimeConfig, clearStandard, handleSetFirstTimeConfig, handleSetClearStandard]);


    useEffect(() => {

        if (codeParts.length === 0 && baseStandard && baseStandard.classifiers) {
            console.log('Adding code parts from base standard:', baseStandard.classifiers);

            addBaseStandard();
        }

        else if (codeParts.length > 0 && baseStandard && baseStandard.classifiers) {
            addBaseStandard();
        }
    }, [baseStandard]);

    const clearBaseStandard = () => {
        setBaseStandard(null);
        handleSetClearStandard(false)

    };

    const addBaseStandard = useCallback(() => {
        if (baseStandard && baseStandard.classifiers) {
            const codePartsFromBaseStandard = Object.values(baseStandard.classifiers).map(
                ({ id, name, description, prompt, isHierarchy, data }) => ({
                    id,
                    name,
                    description,
                    prompt,
                    isHierarchy,
                    data,
                })
            );

            const enrichers = {
                tags: [],
                searchTerms: []
            };

            //console.log('Code parts from base standard:', codePartsFromBaseStandard);

            setCodeParts((prevCodeParts) => {
                const updatedCodeParts = [...prevCodeParts, ...codePartsFromBaseStandard];

                // Dispatch updated code parts to the context
                dispatch({
                    type: 'SET_CODE_PARTS',
                    payload: updatedCodeParts,
                });

                dispatch({
                    type: 'SET_ENRICHERS',
                    payload: enrichers,
                })

                return updatedCodeParts;
            });
        } else {
            console.warn("baseStandard.classifiers is undefined or not an object.");
        }
    }, [baseStandard, dispatch]);


    useEffect(() => {
        //console.log("code parts available? ", hasCodeParts);

        if (hasCodeParts === true) {
            setOpenEditor(false);
        } else {
            setOpenEditor(false);
        }

        if (hasCodeParts === false && uploadFromExcel === true && step === 1) {
            handleFileUpload()
        }
    }, [hasCodeParts, uploadFromExcel, step]);

    useEffect(() => {
        const allHaveValues = codeParts && codeParts.length > 0 && codeParts.every(codePart => codePart.data.length > 0);
        console.log("CODE PARTS:", codeParts);
        console.log(allHaveValues);
        setAllCodePartsHaveValues(allHaveValues);
        setHasCodeParts(codeParts !== null && codeParts.length > 0); // Update hasCodeParts based on codeParts
    }, [codeParts]);



    const addCodePart = (name) => {
        const customPartIndex = codeParts.filter((part) => part.id.startsWith('Custom')).length + 1;
        const newCodePart = {
            id: `Custom${customPartIndex}`,
            name: name || '',
            isHierarchy: false,
            data: [{ code: '', description: '' }]
        };

        // Save the new code part to sessionStorage with blank tableData
        sessionStorage.setItem(newCodePart.id, JSON.stringify(newCodePart.data));

        // Update the state with the new code part
        setCodeParts((prevCodeParts) => {
            const updatedCodeParts = [...prevCodeParts, newCodePart];

            // Dispatch updated code parts to the context
            dispatch({
                type: 'SET_CODE_PARTS',
                payload: updatedCodeParts,
                tableData: { ...workspaceStandard.tableData, [newCodePart.id]: newCodePart.data },
            });

            return updatedCodeParts;
        });
    };

    // Function to close the confirmation modal
    const handleCloseConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    const deleteAllCodeParts = () => {
        // Remove all code parts from sessionStorage
        codeParts.forEach((part) => {
            sessionStorage.removeItem(part.id);
        });

        // Clear the code parts state
        setCodeParts([]);

        // Dispatch an empty array to the context to clear code parts from workspaceStandard
        dispatch({
            type: 'SET_CODE_PARTS',
            payload: [],
            tableData: {}, // Clear tableData as well
        });

        setShowDeleteConfirmation(false)

        clearBaseStandard();
    }

    const deleteCodePart = (id) => {
        setCodeParts((prevCodeParts) => {
            const updatedCodeParts = prevCodeParts.filter((part) => part.id !== id);

            // Only proceed with the dispatch if there are still code parts left
            if (updatedCodeParts.length > 0) {
                // Clear sessionStorage for the specific code part
                sessionStorage.removeItem(id);

                // Dispatch updated code parts to the context
                dispatch({
                    type: 'SET_CODE_PARTS',
                    payload: updatedCodeParts,
                    tableData: {
                        ...workspaceStandard.tableData,
                        [activeCodePart]: sessionStorage.getItem(activeCodePart),
                    },
                });

                dispatch({
                    type: 'CUSTOM_STANDARD_FLAG',
                    payload: true,
                });
            }

            return updatedCodeParts;
        });
    };


    const updateCodePart = (id, name, event) => {

        setCodeParts((prevCodeParts) => {
            const updatedCodeParts = prevCodeParts.map((part) => (part.id === id ? { ...part, name } : part))

            // Dispatch updated code parts to the context
            dispatch({
                type: 'SET_CODE_PARTS',
                payload: updatedCodeParts
            });

            return updatedCodeParts;
        })
    };

    const moveCodePart = (fromId, toId) => {
        setCodeParts((prevCodeParts) => {
            const updatedCodeParts = [...prevCodeParts];
            const fromIndex = updatedCodeParts.findIndex((part) => part.id === fromId);
            const toIndex = updatedCodeParts.findIndex((part) => part.id === toId);

            const [movedPart] = updatedCodeParts.splice(fromIndex, 1);
            updatedCodeParts.splice(toIndex, 0, movedPart);

            return updatedCodeParts;
        });
    };

    // Code value table updates
    const deleteRow = (codePartIndex, rowIndex) => {
        // Remove the row from the specified code part
        setCodeParts((prevCodeParts) =>
            prevCodeParts.map((codePart, index) =>
                index === codePartIndex
                    ? {
                        ...codePart,
                        data: codePart.data.filter((_, idx) => idx !== rowIndex),
                    }
                    : codePart
            )
        );
    };

    const addRow = (codePartIndex) => {
        // Find the code part by its index
        const codePart = codeParts[codePartIndex];

        // Create a new row with empty values
        const newRow = { code: '', description: '' };

        // Update the state to add the new row to the specified code part
        setCodeParts((prevCodeParts) =>
            prevCodeParts.map((prevCodePart, index) =>
                index === codePartIndex
                    ? {
                        ...prevCodePart,
                        data: [...prevCodePart.data, newRow],
                    }
                    : prevCodePart
            )
        );
    };

    const handleSave = (codePartIndex) => {
        // Update sessionStorage with the latest data
        const updatedSessionData = { ...workspaceStandard.tableData, [codePartIndex]: codeParts[codePartIndex].data };
        sessionStorage.setItem(codeParts[codePartIndex].id, JSON.stringify(updatedSessionData));

        // Call the onSave prop with the codePartIdentifier and the updated tableData
        handleSaveCodeValueEntry(codeParts[codePartIndex].id, codeParts[codePartIndex].data);
    };

    const updateRow = (codePartIndex, rowIndex, field, value) => {
        setCodeParts((prevCodeParts) =>
            prevCodeParts.map((prevCodePart, index) =>
                index === codePartIndex
                    ? {
                        ...prevCodePart,
                        data: prevCodePart.data.map((rowData, rowIdx) =>
                            rowIdx === rowIndex ? { ...rowData, [field]: value } : rowData
                        ),
                    }
                    : prevCodePart
            )
        );

        // Call handleSave when a field is updated
        handleSave(codePartIndex);
    };

    const saveRow = (codePartIndex) => {
        // Call handleSave when onBlur event is triggered
        handleSave(codePartIndex);
    };





    useEffect(() => {
        console.log("Code parts: ", codeParts);
    }, [codeParts]);

    const handleShowTableModal = (codePartId) => {
        setShowTableModal(true);
        setActiveCodePart(codePartId);
    };

    const handleHideTableModal = () => {
        setShowTableModal(false);
        setActiveCodePart(null);
    };

    const handleSaveCodeValueEntry = (codePartIdentifier, tableData) => {
        // Handle saving the tableData for the specific code part using session storage
        sessionStorage.setItem(codePartIdentifier, JSON.stringify(tableData));

        // Use the latest state from the context instead of workspaceStandard.tableData directly
        const updatedTableData = { ...workspaceStandard.tableData, [codePartIdentifier]: tableData };

        // Check if the code part exists in workspaceStandard.classifiers
        const existingCodePartIndex = workspaceStandard.classifiers.findIndex(
            (classifier) => classifier.id === codePartIdentifier
        );

        // If the code part exists, update its data; otherwise, log an error (or handle it as needed)
        if (existingCodePartIndex !== -1) {
            // Update the existing code part's data
            const updatedClassifiers = [...workspaceStandard.classifiers];
            updatedClassifiers[existingCodePartIndex].data = tableData;

            // Dispatch the updated context with the new code parts
            dispatch({
                type: 'SET_CODE_PARTS',
                payload: updatedClassifiers,
                tableData: updatedTableData,
            });

            // Update the context with the new table data
            dispatch({
                type: 'SET_TABLE_DATA',
                payload: updatedTableData,
            });
        } else {
            console.error(`Code part with identifier ${codePartIdentifier} not found.`);
            // Optionally, you can handle the error or log it as needed.
        }
    };

    useEffect(() => {
        const handleBeforeUnload = () => {
            // Clear sessionStorage for each code part
            codeParts.forEach((part, index) => {
                sessionStorage.removeItem(`part_${index + 1}`);
            });
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [codeParts]);

    // Export standard to Excel for future use by user
    const handleExportToExcel = () => {
        const wb = XLSX.utils.book_new();
        const fileName = 'My_Standard.xlsx';

        // Iterate over codeParts and add a sheet for each code part
        codeParts.forEach((codePart) => {
            const codePartData = codePart.data;

            console.log(codePartData);

            // Ensure codePartData is an array
            const data = Array.isArray(codePartData) ? codePartData : [];

            // console.log(`CodePart: ${codePart.name}, Data: `, data); // Log the data

            const ws = XLSX.utils.json_to_sheet(data);
            const sheetName = codePart.name;
            XLSX.utils.book_append_sheet(wb, ws, sheetName);
        });

        XLSX.writeFile(wb, fileName);
    };

    useEffect(() => {
        // Clear sessionStorage for each code part when the component is dismounted (i.e. page refresh)
        codeParts.forEach((codePart) => {
            sessionStorage.removeItem(codePart.id);
        });
    }, []);

    const handleFileUpload = () => {
        if (codeParts.length > 0) {
            if (showOverwriteConfirmation) {
                setShowOverwriteConfirmation(false)
                document.getElementById('file-upload').click()
            } else {
                setShowOverwriteConfirmation(true)
            }
        } else {
            document.getElementById('file-upload').click()
        }
    }

    console.log(codeParts)

    const FileUpload = (event) => {

        console.log("FileUpload function called");

        console.log("Event object:", event);

        if (!event.target.files || event.target.files.length === 0) {
            // No file uploaded, so return early
            console.log("No file uploaded");
            return;
        }

        const file = event.target.files[0];
        console.log("Selected file:", file);

        if (!file.name.endsWith('.xlsx') && !file.name.endsWith('.xls')) {
            handleSetToastMessage('Invalid file type. Please upload an Excel file.');
            return;
        }

        const reader = new FileReader();

        reader.onload = (event) => {
            try {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: 'array' });

                // Assuming each sheet corresponds to a code part
                const codeParts = workbook.SheetNames.map((sheetName, index) => {
                    const sheet = workbook.Sheets[sheetName];
                    const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                    // Get the headers from the first row of the sheet
                    const headers = sheetData[0];

                    // Check if there's a 'parent' column and determine if hierarchy exists
                    const parentColumnIndex = headers.indexOf('parent');
                    const isHierarchy = parentColumnIndex !== -1;

                    // Get other column indexes
                    const codeColumnIndex = headers.indexOf('code');
                    const descriptionColumnIndex = headers.indexOf('description')
                    const promptColumnIndex = headers.indexOf('prompt')

                    // Skip the first row (headers) and process the data
                    const codePartData = sheetData.slice(1).map((row) => {
                        const codePart = {
                            code: row[codeColumnIndex] || '',
                            description: row[descriptionColumnIndex] || '',
                            prompt: row[promptColumnIndex] || '',
                        };

                        // If the 'parent' column exists, add the parent field to the codePart
                        if (isHierarchy) {
                            codePart.parent = row[parentColumnIndex] || '';
                        }

                        return codePart;
                    });

                    // Generate the unique ID
                    const sheetId = `ID${index + 1}-${sheetName}`;

                    console.log("Hierarchy flag of ", sheetName, " is ", isHierarchy)

                    return {
                        id: sheetId,
                        name: sheetName,
                        isHierarchy: isHierarchy, // Add the isHierarchy flag
                        data: codePartData,
                    };
                });

                handleSetClearStandard(true);

                deleteAllCodeParts();

                // Update base standard with the uploaded code parts
                setBaseStandard({ classifiers: codeParts, enrichers: { "tags": [], "searchTerms": [] } });

            } catch (error) {
                handleSetToastMessage('An error occurred. Check contents of file and try again.');
                console.error('Error parsing Excel file:', error);
            } finally {
                reader.onload = null; // Reset the onload event handler
            }
        };


        reader.readAsArrayBuffer(file);
        event.target.value = '' // Reset the value of the file input

    };

    // const { getRootProps, getInputProps } = useDropzone({
    //     accept: '.xlsx, .xls',
    //     onDrop: handleFileUpload,
    // });

    const toggleCollapse = () => {
        setCollapsed(!isCollapsed);
    };

    const isTabDataValid = () => {
        // Iterate through codeParts to check each tab's data
        for (const codePart of codeParts) {
            for (const row of codePart.data) {
                if (!row.code || !row.description) {
                    return false; // Return false if any row in any tab has null data
                }
            }
        }

        return true;

    };

    const isValid = isTabDataValid();

    setAllCodePartsHaveValues(isValid);

    const handleProceed = () => {
        if (isTabDataValid()) {
            // Proceed to the next step
        } else {
            // Show an error message or prevent the user from proceeding
            handleSetToastMessage('Please fill in all data for each code part before proceeding.');
        }
    };

    const handleRowSelect = (rowIndex) => {
        if (selectedRows.has(rowIndex)) {
            selectedRows.delete(rowIndex);
        } else {
            selectedRows.add(rowIndex);
        }
        setSelectedRows(new Set(selectedRows)); // Trigger re-render
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            // Select all
            const allSelected = new Set(filteredData.map((_, index) => index)); // Create a set of all row indexes
            setSelectedRows(allSelected); // Set selected rows state to all selected
        } else {
            // Deselect all
            setSelectedRows(new Set()); // Clear selected rows
        }
    };


    const handleBulkDelete = () => {
        setCodeParts((prevCodeParts) => {
            return prevCodeParts.map((codePart, index) => {
                if (index === selectedTab) {
                    return {
                        ...codePart,
                        data: codePart.data.filter((_, rowIndex) => !selectedRows.has(rowIndex)),
                    };
                }
                return codePart;
            });
        });
        setSelectedRows(new Set()); // Reset selection
    };

    const handlePasteFromExcel = (e) => {
        e.preventDefault();
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('text');

        // Split rows and then split columns
        const parsedRows = pastedData.split('\n').map((row) =>
            row.split('\t').map((cell) => cell.trim())
        );

        setCodeParts((prevCodeParts) => {
            return prevCodeParts.map((codePart, index) => {
                if (index === selectedTab) {
                    const newRows = parsedRows.map(([code, description]) => ({
                        code: code || '',
                        description: description || '',
                    }));
                    return { ...codePart, data: [...codePart.data, ...newRows] };
                }
                return codePart;
            });
        });
    };

    const filteredData = codeParts[selectedTab]?.data.filter(row =>
        row.code.includes(searchTerm) || row.description.includes(searchTerm)
    );

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            addRow(selectedTab); // Trigger adding a new row
        }
    };

    return (
        <div >
            <div className={openEditor ? "code-editor-visible" : "code-editor-hidden"}>
                <Container className=" position-relative">
                    <Col md={10}>
                        {/*<p className="">Create or edit your <b>Classifiers</b>. Choose the main categories for your files to be sorted against. Ensure the sequential order of the <b>Classifiers</b> conforms to your file naming convention.</p>*/}
                    </Col>
                    <DndProvider backend={HTML5Backend}>
                        <Container className="">
                            <Row className="mt-3 mb-3">
                                <Col key={0} className="d-flex">
                                    <div className="excel-container">
                                        <div className="excel-icon-overlay">
                                            <FontAwesomeIcon icon={faFileExcel} />
                                        </div>
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={<Tooltip id="tooltip-save">If you have spent time customising your standard, be sure to save a copy to Excel for easy upload next time.</Tooltip>}
                                        >
                                            <div onClick={handleExportToExcel} className="icon-button">
                                                <SaveIcon />
                                            </div>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={<Tooltip id="tooltip-upload">If you've previously saved a standard to Excel, you can upload it here and use it again!</Tooltip>}
                                        >
                                            <div onClick={handleFileUpload} className="icon-button">
                                                <FileUploadIcon />
                                            </div>
                                        </OverlayTrigger>
                                    </div>

                                    <input id="file-upload" type="file" accept=".xls,.xlsx" onChange={FileUpload} style={{ display: 'none' }} />
                                </Col>
                                <Col className='gr-0 text-end d-flex'>
                                    <div className="ms-auto d-flex gap-2">
                                        <Button variant="danger" disabled={codeParts.length === 0} onClick={() => setShowDeleteConfirmation(true)}>Clear all</Button>
                                        <Button variant="secondary" onClick={() => addCodePart('')} className="">Add Category</Button>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="">
                                {codeParts.map((codePart) => (
                                    <CodePart
                                        key={codePart.id}
                                        codePart={codePart}
                                        moveCodePart={moveCodePart}
                                        updateCodePart={updateCodePart}
                                        deleteCodePart={deleteCodePart}
                                        showTableModal={() => handleShowTableModal(codePart.id)}
                                    />

                                ))}
                            </Row>
                            {/* Render CodeValueEntry component if showTableModal is true */}
                            {showTableModal && (
                                <CodeValueEntry
                                    show={showTableModal}
                                    onHide={handleHideTableModal}
                                    onSave={handleSaveCodeValueEntry}
                                    codePartIdentifier={activeCodePart}
                                    baseStandard={baseStandard}
                                />
                            )}
                            {/* Render DeleteConfirmation component if showDeleteConfirmation is true */}
                            <Modal show={showDeleteConfirmation} onHide={handleCloseConfirmation}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Confirmation</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    Are you sure you want to clear all code parts?
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseConfirmation}>
                                        Cancel
                                    </Button>
                                    <Button variant="danger" onClick={deleteAllCodeParts}>
                                        Clear All
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            {/*<Popup
                            show={showOverwriteConfirmation}
                            onHide={() => setShowOverwriteConfirmation(false)}
                            onConfirm={handleFileUpload}
                            callToAction='Overwrite All'
                            title='Confirmation'
                            content='Selecting a new template will overwrite your current standard. Are you sure you want to continue?'
                    />*/}

                        </Container>
                        {/*<ClassificationStandardSummary
                        workspaceStandard={workspaceStandard}
                        isCollapsed={isCollapsed}
                        toggleCollapse={toggleCollapse}
                        />*/}
                    </DndProvider>
                </Container>
            </div>

            <Container className="mt-3 position-relative">
                <Row>
                    {/*<Col xs={10}>
                    <p>We will match each file to the most appropriate <b>Reference Code</b> for each <b>Classifier</b>. Each <b>Classifier</b> needs at least one option for our AI to choose from. 
                    You can copy and paste <b>Reference Codes</b> and <b>Descriptions</b> from Excel if you have them listed elsewhere.</p>
                </Col>*/}
                    <Col xs={2}>

                    </Col>
                </Row>

                <Tab.Container activeKey={`codePart-${selectedTab}`}>
                    <Row>
                        <Col md={3}>
                            <div className="d-flex w-100 align-items-center" style={{ marginTop: "6px" }}>
                                <Form.Control
                                    type="text"
                                    placeholder="Add Category..."
                                    value={categoryName}
                                    onChange={(e) => setCategoryName(e.target.value)}
                                    className="me-2"
                                    style={{ flex: 1 }}
                                />
                                <PlaylistAddIcon
                                    onClick={() => {
                                        if (categoryName) {
                                            addCodePart(categoryName); // Pass the name to addCodePart
                                            setCategoryName(''); // Clear input after adding
                                        }
                                    }}
                                    style={{
                                        color: categoryName ? 'green' : 'gray',
                                        cursor: categoryName ? 'pointer' : 'not-allowed',
                                        marginRight: '6px',
                                        marginLeft: '6px'
                                    }}
                                />
                                <PlaylistRemoveIcon
                                    style={{
                                        color: 'gray',
                                        cursor: 'not-allowed'
                                    }}
                                />
                            </div>

                            <Nav style={{ borderRadius: '5px !important', marginTop: '6px' }} variant="pills" className="flex-column">
                                {codeParts.map((codePart, index) => (
                                    <Nav.Item key={index} style={{ marginTop: "5px" }}>
                                        <Nav.Link
                                            eventKey={`codePart-${index}`}
                                            className={selectedTab === index ? 'active custom-nav-link' : 'custom-nav-link'}
                                            onClick={() => setSelectedTab(index)}

                                        >
                                            {codePart.name}
                                        </Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                        </Col>
                        <Col md={9}>
                            <Tab.Content>
                                {codeParts.map((codePart, index) => (
                                    <Tab.Pane className="tab-content" key={index} eventKey={`codePart-${index}`}>
                                        <div className="d-flex w-100 align-items-center">
                                            <Form.Control
                                                type="text"
                                                placeholder="Search..."
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                className="me-2"
                                                style={{ flex: 1 }}
                                            />
                                            <Button
                                                variant="secondary"
                                                style={{ color: 'white' }}
                                                onClick={() => setOpenEditor(!openEditor)}
                                            >
                                                {openEditor ? 'Close Editor' : 'Open Editor'}
                                            </Button>
                                        </div>
                                        <Table responsive>
                                            <thead>
                                                <tr style={{ height: '60px' }}>
                                                    <th style={{ maxWidth: '70px', verticalAlign: 'middle', textAlign: 'center', backgroundColor: "transparent" }}>
                                                        <Form.Check
                                                            type="checkbox"
                                                            checked={selectedRows.size === filteredData.length} // Check if all are selected
                                                            indeterminate={selectedRows.size > 0 && selectedRows.size < filteredData.length} // Show indeterminate state if some are selected
                                                            onChange={handleSelectAll} // Handle the change to select or deselect all
                                                            title="Select All"
                                                        />
                                                    </th>
                                                    <th style={{ width: '140px', verticalAlign: 'middle', backgroundColor: "transparent" }}>
                                                        <div className="d-flex">
                                                            <div
                                                                style={{
                                                                    maxWidth: '70px',
                                                                    cursor: isSelected ? 'pointer' : '',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    marginRight: '20px'
                                                                }}
                                                                onClick={handleBulkDelete}
                                                                title="Delete Selected" // Optional: Adds a tooltip
                                                                className="delete-icon"
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faTrashAlt}
                                                                    style={{
                                                                        fontSize: '16px',
                                                                        color: isSelected ? 'red' : 'gray', // Change color based on selection
                                                                        transition: 'color 0.2s ease', // Smooth transition
                                                                    }}
                                                                />
                                                            </div>
                                                            Code
                                                            <AddCircleIcon
                                                                onClick={() => addRow(index)}
                                                                style={{ marginLeft: "15px", color: 'green', cursor: 'pointer' }} />
                                                        </div>
                                                    </th>
                                                    <th style={{ verticalAlign: 'middle', backgroundColor: "transparent" }}>
                                                        <div className="d-flex">
                                                            Description
                                                            <Form.Check
                                                                type="switch"
                                                                id="toggle-prompt"
                                                                label=""
                                                                checked={showPrompt}
                                                                onChange={() => setShowPrompt(!showPrompt)}
                                                                style={{ marginLeft: '10px' }}
                                                            />
                                                        </div>
                                                    </th>
                                                    {showPrompt && <th style={{ verticalAlign: 'middle', backgroundColor: "transparent" }}>Prompt</th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredData?.map((row, rowIndex) => (
                                                    <tr key={rowIndex}>
                                                        <td style={{ width: '50px', textAlign: 'center', verticalAlign: 'middle', backgroundColor: "transparent", borderBottom: "none" }}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                onChange={() => handleRowSelect(rowIndex)}
                                                                checked={selectedRows.has(rowIndex)}
                                                                style={{ margin: 0 }} // Reduce margin
                                                            />
                                                        </td>
                                                        <td style={{ verticalAlign: 'middle', backgroundColor: "transparent", borderBottom: "none" }}>
                                                            <Form.Control
                                                                type="text"
                                                                value={row.code}
                                                                onChange={(e) => updateRow(index, rowIndex, 'code', e.target.value)}
                                                                onBlur={() => saveRow(index)}
                                                                style={{ width: '130px' }} // Reduce width of the code input
                                                                onKeyDown={handleKeyDown}
                                                            />
                                                        </td>
                                                        <td style={{ verticalAlign: 'middle', backgroundColor: "transparent", borderBottom: "none" }}>
                                                            <Form.Control
                                                                as={showPrompt ? "textarea" : "input"} // Toggle between textarea and input
                                                                rows={showPrompt ? 3 : 1} // Change rows based on the toggle
                                                                value={row.description}
                                                                onChange={(e) => updateRow(index, rowIndex, 'description', e.target.value)}
                                                                onBlur={() => saveRow(index)}
                                                                style={{ resize: 'none', overflow: 'hidden' }} // Disable resizing
                                                                onKeyDown={handleKeyDown}
                                                            />
                                                        </td>
                                                        {showPrompt && (
                                                            <td style={{ verticalAlign: 'middle', backgroundColor: "transparent", borderBottom: "none" }}>
                                                                <Form.Control
                                                                    as="textarea" // Always a textarea for prompt
                                                                    rows={3} // Set number of visible rows for prompt
                                                                    value={row.prompt}
                                                                    onChange={(e) => updateRow(index, rowIndex, 'prompt', e.target.value)}
                                                                    onBlur={() => saveRow(index)}
                                                                    style={{ resize: 'none', overflow: 'hidden' }} // Disable resizing
                                                                    onKeyDown={handleKeyDown}
                                                                />
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>

        </div>
    );
};

export default CustomiseWorkspaceStandard;