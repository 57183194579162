import React, { useState, useEffect, useCallback } from "react";
import { Typography, Box, Divider, CircularProgress } from '@mui/material';
import { Nav, Tab } from 'react-bootstrap';
import ModifyWorkspaceStandard from "./ModifyWorkspaceStandard";
import DefineWorkspaceEnrichers from "./DefineWorkspaceEnrichers";

const WorkspaceRefinery = ({ workspaceStandard, setWorkspaceStandard }) => {

    const [selectedTab, setSelectedTab] = useState(1);

    const handleTabSelect = (tab) => {
        setSelectedTab(tab);
    };

    return (

        <Box sx={{ margin: '1.5rem', flexGrow: 1, display: 'flex', flexDirection: 'column', position: 'relative' }}>
            {/* Title and Buttons Area */}
            <div style={{ width: '100%' }}>
            </div>
            {Object.keys(workspaceStandard).length > 0 ? (
                <div style={{ width: '100%' }}>
                    <Tab.Container activeKey={selectedTab} onSelect={handleTabSelect}>
                        <Nav className="nav nav-pills">
                            <Nav.Item>
                                <Nav.Link eventKey={1}>Classifiers</Nav.Link>
                            </Nav.Item>
                            <Divider style={{ margin: '0 2px' }} />
                            <Nav.Item>
                                <Nav.Link eventKey={2}>Enrichers</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Divider style={{ border: '1px solid grey', margin: '8px 0' }} />
                        <Tab.Content>
                            <Tab.Pane eventKey={1}>
                                <ModifyWorkspaceStandard workspaceStandard={workspaceStandard} setWorkspaceStandard={setWorkspaceStandard} />
                            </Tab.Pane>
                            <Tab.Pane eventKey={2}>
                                <DefineWorkspaceEnrichers workspaceStandard={workspaceStandard} setWorkspaceStandard={setWorkspaceStandard} />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            )
            :
            <CircularProgress/>
            }
        </Box>
    )
}

export default WorkspaceRefinery;