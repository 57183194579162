import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import HeaderBar from './shared/HeaderBar';
import BackButton from './shared/BackButton';
import DynamicTable from './shared/DynamicTable';
import WorkspaceRefinery from './subcomponents/WorkspaceRefinery';
import TreeView from './shared/TreeView';
import { Container, Spinner, Alert, Tab, Nav, Badge, Row, Col, Modal, Form, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import SaveIcon from '@mui/icons-material/Save';
import GrainIcon from '@mui/icons-material/Grain';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ReportsIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useUserManagement, useCheckPermissions } from '../contexts/UserManagementContexts';
import { useSession } from '../contexts/SessionContexts';
import { generateSessionId } from '../scripts/generateSessionId';
import { Dialog, TextField, DialogActions, DialogContent, DialogContentText, DialogTitle, Chip } from '@mui/material';
import ConfirmDeleteModal from './shared/ConfirmDeleteModal';
import CustomAlert from './shared/CustomAlert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Workspace = () => {
    const { user } = useAuth0();
    const { currentOrg, isLoading, isLoadingOrgs } = useUserManagement();
    const { workspace } = useParams();
    const checkPermissions = useCheckPermissions()
    const navigate = useNavigate();
    const [isLoadingSessions, setIsLoadingSessions] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [workspaceSessions, setWorkspaceSessions] = useState([]);
    const [workspaceStandard, setWorkspaceStandard] = useState(null);
    const [initialWorkspaceStandard, setInitialWorkspaceStandard] = useState(null);
    const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
    const [sessionName, setSessionName] = useState('');
    const [isCreating, setIsCreating] = useState(false);
    const [error, setError] = useState(null);

    // Alert message
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('info'); // You can change the default type as needed
    const [showSpinner, setShowSpinner] = useState(false);
    const [sessionId, setSessionId] = useState('')

    // Permissions
    const [hasCreatePermission, setHasCreatePermission] = useState(false);
    const [hasDeletePermission, setHasDeletePermission] = useState(false);
    const [hasRequeuePermission, setHasRequeuePermission] = useState(false);

    let config = {
        headers: {
            'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY
        }
    };

    useEffect(() => {
        if (!isLoading && !isLoadingOrgs) {
            const isAuthorised = checkPermissions(['read:sessions'], currentOrg.name)
            if (isAuthorised) {
                const createPermission = checkPermissions(['create:sessions'], currentOrg.name)
                const deletePermission = checkPermissions(['delete:sessions'], currentOrg.name)
                const requeuePermission = checkPermissions(['requeue:files'], currentOrg.name)
                setHasCreatePermission(createPermission)
                setHasDeletePermission(deletePermission)
                setHasRequeuePermission(requeuePermission)
            } else {
                navigate('/access-denied')
            }
        }
    },[user, isLoading, isLoadingOrgs])

    // Fetch sessions when the component mounts
    useEffect(() => {
        if (currentOrg) {
            fetchSessions();
        }
    }, [currentOrg, workspace]);
    
    const fetchSessions = async () => {
        setIsLoadingSessions(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/api/GetWorkspaceSessions`, {
                params: {
                    orgName: currentOrg?.name,
                    workspaceName: workspace
                },
                ...config
            });
            setWorkspaceSessions(response.data.sessions);
            setWorkspaceStandard(response.data.workspace_standard);
            setInitialWorkspaceStandard(response.data.workspace_standard);
        } catch (error) {
            console.error('Error fetching sessions:', error);
            setError('Failed to fetch sessions.');
        } finally {
            setIsLoadingSessions(false);
        }
    };

    useEffect(() => {
        if (JSON.stringify(workspaceStandard) !== JSON.stringify(initialWorkspaceStandard)) {
            setIsSaveButtonEnabled(true);
        } else {
            setIsSaveButtonEnabled(false);
        }
    }, [workspaceStandard, initialWorkspaceStandard]);

    const handleSaveWorkspaceStandard = async () => {
        try {
            const response = await axios.post(`${API_BASE_URL}/api/UpdateWorkspaceStandard`, 
                {
                    orgName: currentOrg?.name,
                    workspaceName: workspace,
                    workspaceStandard: workspaceStandard,
                },
                {
                    headers: {
                        'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY,
                        'Content-Type': 'application/json' // Ensure the content type is set correctly
                    }
                }
            );
    
            if (response.status === 200) {
                setInitialWorkspaceStandard(workspaceStandard);
                setIsSaveButtonEnabled(false);
                triggerAlert('Workspace standard saved successfully.', 'success');
            }
        } catch (error) {
            console.error("Error during saving workspace standard:", error); // Log error details
            triggerAlert('Failed to save workspace standard.', 'error');
        }
    };

    

    const tableHeaders = [
        { label: 'Session Name', key: 'session_name' },
        { label: 'Session Key', key: 'session_id' },
        { label: 'Created By', key: 'created_by' },
        { label: 'Created At', key: 'created_at' },
        { label: 'File Count', key: 'file_count' },
        { label: 'Row Count', key: 'row_count' },
        { label: 'Status', key: 'status' },
    ];

    const tableData = workspaceSessions.map(session => ({
        session_name: session.session_name,
        session_id: session.session_id,
        created_at: new Date(session.created_at).toLocaleString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        }),
        created_by: session.created_by,
        file_count: session.file_count,
        row_count: session.row_count,
        status: session.status,
    }));

    const rowActions = (rowData) => {
        const { session_id } = rowData;
    
        return [
            {
                label: 'Delete session',
                onClick: () => handleDeleteButtonPress(session_id),
                disabled: !hasDeletePermission,
            },
            {
                label: 'Reprocess all docs',
                onClick: () => handleRequeueSession(session_id, true),
                disabled: !hasRequeuePermission,
            },
            {
                label: 'Reprocess uncompleted',
                onClick: () => handleRequeueSession(session_id, false),
                disabled: !hasRequeuePermission,
            },
        ];
    };

    // Navigate to results page on row click
    const handleRowClick = (rowData) => {

        const { session_id } = rowData;

        if (rowData.status === 'Draft') {

            navigate(`/${currentOrg.name}/${workspace}/createsession?session=${session_id}`)

        }
        else
            navigate(`/${currentOrg.name}/${workspace}/${session_id}/results`)

    };

    // Handle session creation
    const handleCreateSession = async () => {
        setIsCreating(true);

        const sessionId = generateSessionId()

        try {
            const response = await axios.get(`${API_BASE_URL}/api/NewWorkspaceSession`, {
                params: {
                    orgName: currentOrg?.name,
                    workspaceName: workspace,
                    sessionGuid: sessionId,
                    sessionName: sessionName,
                    createdBy: user.email,
                },
                ...config
            });

            navigate(`/${currentOrg.name}/${workspace}/createsession?session=${sessionId}`);
            setShowModal(false);
        } catch (error) {
            console.error('Error creating session:', error);
            setError('Failed to create session.');
        } finally {
            setIsCreating(false);
        }
    };

    const handleDeleteButtonPress = (session) => {
        setSessionId(session)
        setShowDeleteModal(true)
    }

    const handleDeleteSession = async () => {
        setShowSpinner(true);

        try {
            const response = await axios.get(`${API_BASE_URL}/api/DeleteSession?containerName=${currentOrg.name}&virtualFolder=${workspace}/${sessionId}`, config);
            triggerAlert("Delete successful.")
        }
        catch {
            triggerAlert('Delete failed.', 'error')
        }
        finally {
            fetchSessions()
            setShowSpinner(false);
        }
        
    }

    const handleRequeueSession = async (session_id, queueCompleted) => {

        setShowSpinner(true);

        try {
            const response = await axios.post(
                `${API_BASE_URL}/api/QueueFiles`,
                {
                    organisation: currentOrg.name,
                    workspace: workspace,
                    sessionId: session_id,
                    userId: user.sub,
                    queueCompleted: queueCompleted
                },
                config
            )

            const fileCount = response.data?.file_count

            if (fileCount > 0 ) (
                triggerAlert(`${fileCount} items(s) queued.`)
            )
            else {
                triggerAlert(`0 items(s) queued.`, 'warning')
            }
        }
        catch {
            triggerAlert('Submit failed, Please try again.', 'error')
        }
        finally {
            fetchSessions()
            setShowSpinner(false);
        }
    }

    const triggerAlert = (newMessage, newType) => {
        setAlertMessage(newMessage);
        setAlertType(newType);
        setShowAlert(true);
    };


    return (
        <div>
            <HeaderBar
                className="secondary-background"
                homeLink="/"
                homeText="hoppa"
                userInfo={user}
                switchingAllowed={false}
            />
            <div className="full-width-white-section">
                <Container>
                    <Row>
                        <Col>
                            <Row className="mt-2">
                                <Col>
                                    <BackButton destinationText="workspaces" destination="/welcome" />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span style={{ fontSize: "1.1rem" }}>
                                        <Badge bg="info" className="mt-1">{workspace}</Badge>
                                    </span>
                                </Col>
                            </Row>                      
                        </Col>
                        <Col>
                        <Col className="d-flex justify-content-center justify-content-md-end align-items-center mt-5">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="save-tooltip">
                                        Save updates to workspace standard for them to be made available the next time you create a session
                                    </Tooltip>
                                }
                            >
                                <span
                                    onClick={isSaveButtonEnabled ? handleSaveWorkspaceStandard : null}
                                    style={{
                                        cursor: isSaveButtonEnabled ? 'pointer' : 'not-allowed',
                                        color: isSaveButtonEnabled ? '#0051B6' : '#ccc',
                                        fontSize: '50px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginRight: '30px'
                                    }}
                                >
                                    <SaveIcon />
                                </span>
                            </OverlayTrigger>
                        </Col>
                        </Col>
                    </Row>
                   
                </Container>
            </div>
            <Tab.Container defaultActiveKey="sessions">
                <div className="full-width-white-bottom-section">
                    <Container>
                        <Nav variant='tabs'>
                            <Nav.Item>
                                <Nav.Link eventKey="sessions" >
                                    <GrainIcon style={{ marginRight: '8px' }} />
                                    Sessions
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="refinery">
                                    <FilterAltIcon style={{ marginRight: '8px' }} />
                                    Data Refinery
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="reports" disabled>
                                    <ReportsIcon style={{ marginRight: '8px' }} />
                                    Reports
                                    <Chip label='Coming soon' size='small' variant='outlined' sx={{color: '#544cfd', borderColor: '#544cfd', marginLeft: '5px'}}/>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="settings" disabled>
                                    <SettingsIcon style={{ marginRight: '8px' }} />
                                    Settings
                                    <Chip label='Coming soon' size='small' variant='outlined' sx={{color: '#544cfd', borderColor: '#544cfd', marginLeft: '5px'}}/>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Container>
                </div>
                <div className="full-width-lt-blue-section">
                    <Container>
                        <Tab.Content>
                            <Tab.Pane eventKey="sessions">
                                {isLoading ? (
                                    <Spinner animation="border" />
                                ) : error ? (
                                    <Alert variant="danger">{error}</Alert>
                                ) : (
                                    <DynamicTable
                                        tableData={tableData}
                                        tableHeaders={tableHeaders}
                                        searchPlaceholder="Search sessions..."
                                        onRowClick={handleRowClick}
                                        isLoading={isLoadingSessions}
                                        actionButton={{
                                            label: 'Create New Session',
                                            disabled: !hasCreatePermission,
                                            onClick: () => setShowModal(true),
                                        }}
                                        rowActions={(rowData) => rowActions(rowData)}
                                    />
                                )
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="refinery">
                            {workspaceStandard && (
                                
                                <div className="mt-3 mb-3">
                                    <WorkspaceRefinery workspaceStandard={workspaceStandard} setWorkspaceStandard={setWorkspaceStandard} />
                                </div>
                                )}

                            </Tab.Pane>

                            
                            <Tab.Pane eventKey="settings">
                                <h3>Settings</h3>
                            </Tab.Pane>
                        </Tab.Content>
                    </Container>
                </div>
            </Tab.Container>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create New Session</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="workspaceName">
                            <Form.Label>Session Name</Form.Label>
                            <Form.Control
                                type="text"
                                autoFocus
                                placeholder="Enter session name"
                                value={sessionName}
                                onChange={(e) => setSessionName(e.target.value)}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleCreateSession();
                                    }
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleCreateSession}
                        disabled={isCreating || !sessionName}
                    >
                        {isCreating ? 'Creating...' : 'Create session'}
                    </Button>
                </Modal.Footer>
            </Modal>

            <ConfirmDeleteModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                fieldName={sessionId}
                onDelete={handleDeleteSession}
            />

            <CustomAlert message={alertMessage} type={alertType} showAlert={showAlert} setShowAlert={setShowAlert} />
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showSpinner}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default Workspace;
